:root {
    --layer-3-morning: #5E4736;
    --layer-3-afternoon: #3C6F72;
    --layer-3-evening: #354D4F;
    --layer-3-night: #354D4F;
}

.layerThree {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(4*100vw);
    transition: left 1s ease-out;
}

.layerThreeSvg svg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.path {
    transition: fill 1s;
}
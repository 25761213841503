.planeWrapper {
    position: absolute;
    width: 490px;
    /* height: 236px; */
    aspect-ratio: 490 / 236;
    top: 30dvh;
    transition: left 1s ease-out;
}

.planeWrapper svg {
    width: 100%;
    height: 100%;
    bottom: 0;
}

@media only screen and (max-height: 600px) {
    .planeWrapper {
        width: 300px;
        top: 125px;
    }
}

@media only screen and (max-height: 400px) {
    .planeWrapper {
        top: 85px;
    }
}
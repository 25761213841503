.sun {
    width: 103px;
    height: 103px;
    border-radius: 50%;
    background-color: #d9d9d9;
    filter: drop-shadow(0 0 .75rem #d9d9d9);
    position: absolute;
    bottom: 330px;
    left: 45px;
    z-index: 2;
    transition: all 800ms ease-out;
}

@media only screen and (max-height: 600px) {
    .sun {
        display: none;
    }
}
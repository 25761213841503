.wrapper {
    position: absolute;
    top: 75px;
    left: 10vw;
}

.h2 {
    font-family: 'Anton', sans-serif;
    font-size: 8.375rem;
    line-height: 8.375rem;
    color: white;
    text-transform: uppercase;
    font-weight: normal;
}

@media only screen and (max-width: 1200px) {

    .h2 {
        font-size: 6rem;
        line-height: 6rem;
    }
}

@media only screen and (max-height: 600px) {
    .wrapper {
        top: 40px;
        left: 5vw;
    }

    .h2 {
        font-size: 5rem;
        line-height: 5rem;
    }
}

@media only screen and (max-height: 400px) {
    .wrapper {
        top: 20px;
        width: 95vw;
        left: 2vw;
    }

    .h2 {
        font-size: 4rem;
        line-height: 4rem;
    }
}

.villageWrapper {
    position: absolute;
    bottom: 60px;
    /* left: 880px; */
    left: 65%;   
    width: 14%;
    /* height: 208px; */
    aspect-ratio: 270 / 208;
    cursor: pointer;
}

.villageWrapper::before {
    content: '';
    opacity: 0;
    width: calc(100% + 40px);
    aspect-ratio: 1 / 1;
    position: absolute;
    border-radius: 50%;
    border: 4px solid white;
    top: -50px;
    left: -30px;
    background-color: rgba(217, 217, 217, 0.25);
    transition: opacity 500ms ease-in-out;
}

.villageWrapper:hover::before, .villageWrapper[data-active="true"]::before {
    opacity: 1;
}


.villageWrapper svg {
    width: 100%;
    height: 100%;
    bottom: 0;
}

.villageWrapper .cta {
    width: 1.375rem;
    height: 1.375rem;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 40%;
    cursor: pointer;
    z-index: 8;
}

.villageWrapper .cta > div {
    width: 0.75rem;
    height: 0.75rem;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .villageWrapper .cta {
        top: -20px;
        left: 20%;
    }
}

@media only screen and (max-height: 600px) {
    .villageWrapper {
        bottom: 10px;
    }
}
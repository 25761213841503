.scrollWrapper {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (orientation: portrait) {
    .scrollWrapper:after {
        content: '';
        background-color: #2C465C;
        position: fixed;
        width: 100vw;
        height: 100dvh;
        top: 0;
        left: 0;
        z-index: 900;
    }
    .scrollWrapper:before {
        position: fixed;
        width: 100vw;
        height: 100dvh;
        content: 'screen_rotation';
        font-family: 'Material Symbols Outlined';
        font-size: 5rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: rotation 5s infinite;
        z-index: 950;
    }  
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.works {
    width: 80vw;
    height: 50dvh;
    border-left: 5px solid white;
    position: absolute;
    top: 0;
    left: 490px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
}

.worksList {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    color: white;
    font-family: 'Montserrat';
    margin: 0;
}

.worksList li {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 200;
    height: 3.5rem;
    cursor: pointer;
    display: flex;
    column-gap: 1.8rem;
    align-items: center;
    transition: background-color 400ms ease-in-out;
}

.worksList li[data-active="true"] {
    background-color: rgba(51, 51, 51, .65);
}

.worksList li[data-active="true"] .skills {
    display: none;
}

.worksList li:hover {
    background-color: rgba(51, 51, 51, .25);
}

@media only screen and (max-width: 1200px) {
    .works {
        grid-template-columns: 1fr fit-content(50%);
    }

    .worksList li {
        font-size: 1.3rem;
        height: 2.5rem;
    }
}

@media only screen and (max-height: 600px) {
    .works {
        border-left: 3px solid white;
        left: 350px;
        grid-template-columns: 1fr fit-content(50%);
    }

    .worksList li {
        font-size: 0.8rem;
        height: 1.5rem;
    }
}
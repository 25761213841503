.windmills {
    position: absolute;
    bottom: -30px;
    left: 25%;
    /* width: 532px; */
    height: 430px;
    aspect-ratio: 532 / 430;
    cursor: pointer;
}

.windmills::before {
    content: '';
    opacity: 0;
    height: 310px;
    aspect-ratio: 1 / 1;
    position: absolute;
    border-radius: 50%;
    border: 4px solid white;
    top: 50%;
    left: 25%;
    transform: translateX(-25%) translateY(-50%);
    background-color: rgba(217, 217, 217, 0.25);
    transition: opacity 500ms ease-in-out;
}
.windmills:hover::before, .windmills[data-active="true"]::before {
    opacity: 1;
}

.windmillWrapper {
    /* width: 190px;
    height: 230px; */
    /* width: 300px; */
    height: 365px;
    aspect-ratio: 300 / 365;
}

.windmillWrapper.left {
    position: absolute;
    top: 0;
    left: 0%;
}
.windmillWrapper.center {
    position: absolute;
    top: 10%;
    left: 12%;
    z-index: 7;
}
.windmillWrapper.right {
    position: absolute;
    top: 0;
    left: 25%;
}

.windmillWrapper svg {
    width: 100%;
    height: 100%;
    bottom: 0;
}

.windmills .cta {
    width: 1.375rem;
    height: 1.375rem;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    top: 100px;
    left: 20%;
    cursor: pointer;
    z-index: 8;
}

.windmills .cta > div {
    width: 0.75rem;
    height: 0.75rem;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    cursor: pointer;
}

@media only screen and (max-height: 800px) {

    .windmills::before {
        transform: translateX(-20%) translateY(-35%);
    }
}

@media only screen and (max-width: 1200px) {
    .windmills {
        bottom: 200px;
        left: 20vw;
        height: 150px;
    }

    .windmills::before {
        transform: translateX(-20%) translateY(0);
    }
}

@media only screen and (max-height: 600px) {
    .windmills {
        bottom: -10px;
        left: 10vw;
        height: 150px;
    }

    .windmills:before {
        height: 150px;
        transform: translateX(0) translateY(0);
        top: 5%;
        left: 5%;
    }

    .windmillWrapper {
        /* width: 190px;
        height: 230px; */
        /* width: 300px; */
        height: 150px;
    }

    .windmills .cta {
        top: 0;
    }
}
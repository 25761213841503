.wrapper {
    position: relative;
    top: 140px;
    left: 210px;
}

.h1 {
    font-family: 'Anton', sans-serif;
    font-size: 12.9375rem;
    text-transform: uppercase;
    background: linear-gradient(#333, #704F35);
    background-clip: text;
    color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 12.9375rem;
    font-weight: normal;
}

.h3 {
    margin-top: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 3.75rem;
    font-weight: 300;
    color: #333;
}

@media only screen and (max-height: 800px) {
    .wrapper {
        top: 100px;
    }
    .h1 {
        font-size: 10rem;
        line-height: 10rem;
    }
    .h3 {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 1200px) {
    .wrapper {
        top: 150px;
        left: 100px;
    }

    .h1 {
        font-size: 8rem;
        line-height: 8rem;
    }
    .h3 {
        font-size: 2.5rem;
    }
    
}

@media only screen and (max-height: 600px) {
    .wrapper {
        top: 70px;
    }
    .h1 {
        font-size: 6rem;
        line-height: 6rem;
    }
    .h3 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-height: 400px) {
    .wrapper {
        top: 40px;
    }
    .h1 {
        font-size: 5rem;
        line-height: 5rem;
    }
    .h3 {
        font-size: 1.5rem;
        margin-top: 0rem;
    }
}
:root {
  --morning-gradient: radial-gradient(ellipse at bottom left, #D2A86B, #805E44);
  --afternoon-gradient: radial-gradient(ellipse at top, #86B2A2, #3C6F72);
  --evening-gradient: radial-gradient(ellipse at bottom right, #369297, #546F7D);
  --night-gradient: radial-gradient(ellipse at top, #367C97, #2C465C);
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

@font-face {
  font-family: 'qp-icons';
  src:
    url('./assets/fonts/qp-icons.ttf?rq5f09') format('truetype'),
    url('./assets/fonts/qp-icons.woff?rq5f09') format('woff'),
    url('./assets/fonts/qp-icons.svg?rq5f09#qp-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body, h1, h2, h3 {
  margin: 0;
}

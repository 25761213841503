:root {
    --gradient-1-morning-start: #6B4C35;
    --gradient-1-morning-end: #543828;
    --gradient-1-afternoon-start: #274A4D;
    --gradient-1-afternoon-end: #2C5C5F;
    --gradient-1-evening-start: #274A4D;
    --gradient-1-evening-end: #2C5C5F;
    --gradient-1-night-start: #164A57;
    --gradient-1-night-end: #2C465F;
}

.layerTwo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(4*100vw);
    transition: left 1s ease-out;
}

.layerTwoSvg svg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.gradient {
    transition: opacity 500ms ease-out;
    /* fill: url(#gradient); */
}
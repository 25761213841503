.workDescriptionWrapper {
    padding: 5px 20px 20px 20px;
    background-color: rgba(51, 51, 51, .65);
    border-radius: 0 5% 0 0;
    font-family: 'Montserrat';
}

.workRealisation {
    color: white;
    font-style: italic;
    font-weight: 300;
    font-size: 1.25rem;
    color: #d9d9d9;
    margin-top: 25px;
}

.workDescription {
    color: white;
    font-size: 1.5rem;
    font-weight: 200;
}

.workDescription ul {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    list-style: none;
}

.workDescription li {
    text-align: justify;
    position: relative;
}

.workDescription li:before {
    position: absolute;
    top: 0;
    left: -40px;
    content: 'arrow_right';
    font-family: 'Material Symbols Outlined';
    font-size: 40px;
}

@media only screen and (max-height: 600px) {
    .workDescriptionWrapper {
        padding: 5px;
    }

    .workRealisation {
        font-size: .7rem;
        margin-top: 5px;
    }

    .workDescription {
        font-size: .8rem;
    }

    .workDescription ul {
        padding-left: 20px;
        row-gap: 15px;
    }

    .workDescription li:before {
        font-size: 20px;
        left: -20px;
    }
}
.rotate {
    transform-origin:center;
    transform-box: fill-box;
    animation-name: rotate; 
    animation-duration: 10s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
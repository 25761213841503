
.swipeLeft {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 48px;
    height: 48px;
    bottom: 80px;
    z-index: 10;
    transform-origin: 24px;
}

.swipeLeft:before {
    content: 'swipe_left';
    font-family: 'Material Symbols Outlined';
    color: white;
    font-size: 48px;
    display: block;
    animation: moveSwipe 5s infinite;
}

@keyframes moveSwipe {
    0% {
        transform: rotate(-30deg);
    }
    50% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(-30deg);
    }
}

.scroll {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 25px;
    aspect-ratio: 1 / 2;
    border-radius: 20px;
    border: 2px solid white;
}

.scroll > div {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: white;
    animation: scroll 5s infinite;
}

@keyframes scroll {
    0% {
        top: 10%;
    }
    50% {
        top: 50%;
    }
    100% {
        top: 10%;
    }
}

.swipeLeft, .scroll {
    transition: opacity 1s ease-in-out;
}
.backgroundWrapper {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #444;
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease;
}

.morningBackground {
    background: var(--morning-gradient);
}

.afternoonBackground {
    background: var(--afternoon-gradient);
}

.eveningBackground {
    background: var(--evening-gradient);
}

.nightBackground {
    background: var(--night-gradient);
}

.childrenWrapper {
    position: relative;
}

.button {
    position: relative;
}

:root {
    --layer-1-morning-start: #785B45;
    --layer-1-morning-end: #653C1D;
    --layer-1-afternoon-start: #1F5F60;
    --layer-1-afternoon-end: #1D3C3E;
    --layer-1-evening-start: #1F5F60;
    --layer-1-evening-end: #1D3C3E;
    --layer-1-night-start: #234C6C;
    --layer-1-night-end: #00273D;
  }

.layerOne {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(4*100vw);
    transition: left 1s ease-out;
}

.layerOneSvg, .layerOneTreeSvg, svg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
}

.gradient {
    transition: opacity 500ms ease-out;
    /* fill: url(#gradient); */
}

.gradient defs stop {
    transition: all 1s ease;
}
.contactWrapper {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 390px;
    height: 390px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* cursor: pointer; */
}

.ctaWrapper {
    position: relative;
    width: 225px;
    height: 225px;
    border-radius: 50%;
    /* background-color: bisque; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
}

.svgWrapper {
    width: 155px;
    height: 130px;
    position: relative;
}

.ctaWrapper .selected {
    content: '';
    opacity: 0;
    width: 100%;
    aspect-ratio: 1 / 1;
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    border: 4px solid white;
    top: 0;
    left: 0;
    background-color: rgba(217, 217, 217, 0.25);
    transition: opacity 500ms ease-in-out;
}

.ctaWrapper:hover > .selected, .contactWrapper[data-active="true"] .selected {
    opacity: 1;
}

.contactWrapper svg {
    width: 100%;
    height: 100%;
    bottom: 0;
}

.cta {
    width: 1.375rem;
    height: 1.375rem;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    top: 30%;
    left: 10%;
    cursor: pointer;
    z-index: 8;
}

.cta > div {
    width: 0.75rem;
    height: 0.75rem;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    cursor: pointer;
}

.icon {
    font-family: 'qp-icons';
    width: 48px;
    height: 48px;
    position: absolute;
    font-size: 48px;
    left: 0;
    transform-origin: 195px;
    transition-property: transform, color;
    transition-duration: 200ms, 500ms;
    transition-delay: calc(100ms * var(--i)), 0s;
    transform: rotate(0deg) translateX(195px);
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}

.icon[data-icon="malt"]:hover {
    color: #DC4667 !important;
}

.icon[data-icon="linkedin"]:hover {
    color: #37C6FF !important;
}

.icon[data-icon="mail"]:hover {
    color: #41B883 !important;
}

.contactWrapper[data-active="true"] .icon {
    transform: rotate(calc(360deg / 8 * var(--i)));
}

.icon:before {
    display: block;
    transform: rotate(calc(360deg / -8 * var(--i)));
}

.icon[data-index="1"] {
    --i: 1;
}

.icon[data-index="2"] {
    --i: 2;
}

.icon[data-index="3"] {
    --i: 3;
}

.icon[data-icon="linkedin"]:before {
    content: "\e90a";
}
.icon[data-icon="mail"]:before {
    content: "\e90b";
}
.icon[data-icon="malt"]:before {
    content: "\e90c";
}


@media only screen and (max-width: 1200px) {
    .contactWrapper {
        bottom: -100px;
    }
}

@media only screen and (max-height: 600px) {
    .contactWrapper {
        width: 200px;
        height: 200px;
        bottom: -50px;
    }

    .ctaWrapper {
        width: 115px;
        height: 115px;
    }

    .icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
        transform-origin: 100px !important;
        transform: rotate(0deg) translateX(100px);
    }
}
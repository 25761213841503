.content {
    position: absolute;
    width: calc(60vw);
    top: 200px;
    left: 20vw;
    transition: opacity 500ms ease-in-out;
}

.content[data-active="false"] {
    opacity: 0;
}

.content h2 {
    font-family: 'Anton', sans-serif;
    font-size: 8.375rem;
    line-height: 8.375rem;
    color: white;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
}

.content p {
    font-size: 1.5rem;
    font-weight: 200;
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}

@media only screen and (max-height: 800px) {
    .content {
        top: 100px;
        width: 75vw;
        left: 10vw;
    }
}

@media only screen and (max-width: 1200px) {
    .content {
        top: 100px;
        width: 75vw;
        left: 10vw;
    }

    .content h2 {
        font-size: 6rem;
        line-height: 6rem;
    }

    .content p {
        font-size: 1.5rem;
    }
}

@media only screen and (max-height: 600px) {
    .content {
        top: 40px;
        width: 85vw;
        left: 5vw;
    }

    .content h2 {
        font-size: 5rem;
        line-height: 5rem;
    }

    .content p {
        font-size: 0.9rem;
    }
}

@media only screen and (max-height: 400px) {
    .content {
        top: 20px;
        width: 95vw;
        left: 2vw;
    }

    .content h2 {
        font-size: 4rem;
        line-height: 4rem;
    }

    .content p {
        font-size: 0.9rem;
    }
}
.skills {
    display: flex;
    column-gap: 1rem;
    justify-content: flex-end;
}

.skillWrapper {
    padding: 4px;
    color: white;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-size: 1.5625rem;
    font-weight: 200;
    column-gap: 12px;
    cursor: pointer;
    user-select: none;
}

.skillWrapper[data-expanded="true"] {
    background-color: #333;
    border-radius: 10px;
}

.icon {
    font-family: 'qp-icons' !important;
    font-size: 1.8rem;
}

.icon[data-icon="wordpress"]:before {
    content: "\e900";
    color: #fff;
}
.icon[data-icon="angular"]:before {
    content: "\e901";
    color: #fff;
}
.icon[data-icon="docker"]:before {
    content: "\e902";
    color: #fff;
}
.icon[data-icon="drupal"]:before {
    content: "\e903";
    color: #fff;
}
.icon[data-icon="nextjs"]:before {
    content: "\e904";
    color: #fff;
}
.icon[data-icon="nodejs"]:before {
    content: "\e905";
    color: #fff;
}
.icon[data-icon="symfony"]:before {
    content: "\e906";
    color: #fff;
}
.icon[data-icon="typescript"]:before {
    content: "\e907";
    color: #fff;
}
.icon[data-icon="reactjs"]:before {
    content: "\e90d";
    color: #fff;
}
.icon[data-icon="vuejs"] .path1:before {
    content: "\e908";
    color: rgb(255, 255, 255);
}
.icon[data-icon="vuejs"] .path2:before {
    content: "\e909";
    margin-left: -1.12890625em;
    color: rgb(255, 255, 255);
    opacity: 0.47;
}

@media only screen and (max-width: 1200px) {
    
    .skillWrapper {
        font-size: 1rem;
    }

    .skillWrapper[data-expandable="false"] {
        display: none;
    }

    .icon {
        font-size: 1.5rem;
    }
}

@media only screen and (max-height: 600px) {
    
    .skillWrapper {
        font-size: .8rem;
    }

    .skillWrapper[data-expandable="false"] {
        display: none;
    }

    .icon {
        font-size: 1rem;
    }
}